.App-header {
  display: flex;
  align-items: baseline;
}

.App-header-item {
  flex-grow: 0;
  margin-left: 10px;
}

.App-header-spacer {
  flex-grow: 1;
}

.App-titles {
  width: 100%;
  text-align: left;
}

.App-brand {
  text-decoration: none;
  color: inherit;
}

.App-mountain {
  width: 100%;
  margin-bottom: 40px;
}

.App-mountain th {
  text-align: left;
}

.App-mountain td {
  text-align: left;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}